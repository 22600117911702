
import { defineComponent } from "vue";

export default defineComponent({
  name: "GuestSetup",
  async created() {
    await this.$store.dispatch("guest/init", true, { root: true });

    const isGuest = this.$store.getters["guest/isGuest"];

    // force all to this action
    const callback = () => {
      this.$router.push({
        name: isGuest ? "GuestAssessmentsCreate" : "AssessmentsCreate",
        params: { step: "name" },
      });
    };
    await this.$store.dispatch(
      "assessmentTemplate/cleanup",
      { callback },
      {
        root: true,
      }
    );
  },
});
